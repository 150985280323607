import http from './axios-client';

// export async function getMissLeaderboard(top: number, offset: number) {
//   const response = await http.get(`/api/v1/landing-data/squads/rank?offset=${offset}&limit=${top}`);
//   if (response.status === 200) {
//     return response.data;
//   } else {
//     return null;
//   }
// }
export async function getMissLeaderboard(top: number, offset: number) {
  const response = await http.get(`/api/v1/public/squad/all?offset=${offset}&limit=${top}`);
  if (response.status === 200) {
    const data = response?.data?.data?.sort((a:any,b:any)=>parseInt(b.total_points)-parseInt(a.total_points)).slice(0,10)
    return {data};
  } else {
    return null;
  }
}

export async function getMemeLeaderboard(top: number, offset: number) {
  const response = await http.get(`/api/v1/public/squad/all?offset=${offset}&limit=${top}`);
  if (response.status === 200) {
    const data = response?.data?.data?.sort((a:any,b:any)=>parseInt(b.market_cap)-parseInt(a.market_cap)).slice(0,10)
    return {data};
  } else {
    return null;
  }
}

export async function getSquadLeaderboard(squadId: string | undefined) {
  const response = await http.get(`/api/v1/landing-data/user/ranks?limit=10&offset=0&squadId=${squadId}`);
  if (response.status === 200) {
    return response.data;
  } else {
    return null;
  }
}

export async function getAllSquad() {
  const response = await http.get('/api/v1/public/squad/all');
  if (response.status === 200) {
    return response.data;
  } else {
    return null;
  }
}

export async function getAllAiSquad() {
  const response = await http.get('/api/v1/public/squad/ai-all');
  if (response.status === 200) {
    const data = response?.data?.data?.sort((a:any,b:any)=>parseInt(b.market_cap)-parseInt(a.market_cap))
    return {data};
  } else {
    return null;
  }
}

export async function getSquadById(squadId: string | undefined) {
  const response = await http.get(`/api/v1/public/squad/${squadId}`);
  if (response.status === 200) {
    return response.data;
  } else {
    return null;
  }
}

export async function getAiSquad(userId: any | undefined) {
  const response = await http.get(`/api/v1/ai/get-squad?address=${userId}`);
  if (response.status === 200) {
    return response.data;
  } else {
    return null;
  }
}

export async function saveMemeTransaction(userId: any | undefined, squad_id: any, amount: number, cost: number, hash: string, from_address: string, to_address: string, contract: string, info: any) {
  const response = await http.get(`/api/v1/ai/meme-transaction?address=${userId}&squad_id=${squad_id}&amount=${amount}&cost=${cost}&hash=${hash}&from_address=${from_address}&to_address=${to_address}&contract=${contract}&info=${info}`);
  if (response.status === 200) {
    return response.data;
  } else {
    return null;
  }
}
