import React, { memo } from 'react';
import { Wrapper } from './loading.styled';

const LoadingComponent = () => {
  return (
    <Wrapper id='loading'>
      <div className=' flex flex-col items-center relative w-full h-full bg-[#12161D] justify-center !py-6 !px-2'>
        <div className='flex flex-col gap-2 items-center'>
          <div className='font-medium text-2xl'>LOADING...</div>
          <div className='lds-roller relative'>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export const Loading = memo(LoadingComponent);
