import React, { memo, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Wrapper } from './purchar.styled';
import LeftArrow from '@/assets/left-arrow.svg';
import instagram from '@/assets/icon/instagram.png';
import { Footer } from '@/components/footer/footer';
import { getAiSquad, getSquadById, saveMemeTransaction } from '@/utilities/miss-data';
import { Link } from 'react-router-dom';
import frame from '@/assets/icon/frame.png';
import commingsoon from '@/assets/icon/comingsoon.png';
import xp from '@/assets/icon/xp.png';
import { ENVS } from '@/config/env.config';
import { ethers } from "ethers";
import { toast } from 'react-toastify';
import { ConnectButton } from "@rainbow-me/rainbowkit";
import axios from 'axios';
import { useAccount, useAccountEffect } from 'wagmi'
type TelegramAuthData = {
    id: number;
    username?: string;
    first_name?: string;
    last_name?: string;
    auth_date?: number;
    hash: string;
};
type AuthData = {
    user_id: number;
    username?: string;
    address?: string;
    country?: string;
    miss: string;
    avatar: string;
    flag: string;
    ai_avatar: string;
    squad_id: string;
};
const PurcharMemeComponent = () => {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const [squad, setSquad] = useState<any>({});

    useEffect(() => {
        window.scrollTo(0, 0);
        if (id) {
            async function fetchSquadDetailData() {
                const squadResponse = await getSquadById(id);
                if (squadResponse) {
                    setSquad(squadResponse.data);
                }
            }
            fetchSquadDetailData();
        }

    }, []);

    const [data, setData] = useState<TelegramAuthData | null>(null);
    const [user, setUser] = useState<AuthData | null>(null);
    useEffect(() => {
        try {
            const storedData = localStorage.getItem('authData');
            if (storedData) {
                const parsedData = JSON.parse(storedData);

                if (parsedData && parsedData.id && parsedData.username) {
                    setData(parsedData);
                    getAiSquad(parsedData.id).then((squadResponse) => {
                        if (squadResponse && squadResponse.data && squadResponse.data.length > 0) {
                            setUser(squadResponse.data[0]);
                        }
                    }).catch((error) => {
                        console.error('Error fetching squad data:', error);
                    });
                } else {
                    console.error('Clearing data.');
                    localStorage.removeItem('authData');
                }
            }
        } catch (error) {
            localStorage.removeItem('authData');
        }
    }, []);


    const [amount, setAmount] = useState<string>('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.replace(',', '.');
        setAmount(value);
    };
    const [loading, setLoading] = useState(false);
    const [balanceMeme, setBalanceMeme] = useState(0);
    const handleSendTransaction = async () => {
        setLoading(true)
        try {
            const provider = new ethers.providers.Web3Provider(window.ethereum as unknown as ethers.providers.ExternalProvider, "any");
            await provider.send("eth_requestAccounts", []);
            const signer = provider.getSigner();
            const sender = await signer.getAddress();
            const CONTRACT_ADDRESS = squad?.contract_address;

            if (!CONTRACT_ADDRESS) {
                throw new Error("Contract address is not defined");
            }

            const DECIMALS = 18;
            const abi = [
                "function balanceOf(address owner) view returns (uint256)",
                "function transfer(address to, uint256 amount)"
            ];

            const erc20 = new ethers.Contract(CONTRACT_ADDRESS, abi, signer);

            const balance = await erc20.balanceOf(sender);
            console.log("Token Balance:", ethers.utils.formatUnits(balance, DECIMALS));

            const memeBalance = parseFloat(ethers.utils.formatUnits(balance, DECIMALS));
            if (parseFloat(amount) <= 0) {
                toast.error('Amount must be greater than 0!');
                return;
            }

            if (memeBalance < parseFloat(amount)) {
                toast.error('Insufficient token balance!');
                return;
            }

            const transferAmount = ethers.utils.parseUnits(amount, DECIMALS);
            const txResponse = await erc20.transfer(`${ENVS.VITE_MEME_ADMIN_WALLET}`, ethers.utils.parseUnits(amount, 18));
            console.log("Transaction sent! Hash:", txResponse.hash);

            const txReceipt = await txResponse.wait();
            saveMemeTransaction(data?.id || '5517140089', id, parseFloat(amount), parseFloat(amount), txResponse.hash, sender, ENVS.VITE_MEME_ADMIN_WALLET, CONTRACT_ADDRESS, txReceipt);
            console.log("Transaction confirmed:", txReceipt);
            setLoading(false);

            if (txReceipt.status === 1) {
                const balanceAfter = await erc20.balanceOf(sender);
                const formattedBalanceAfter = ethers.utils.formatUnits(balanceAfter, DECIMALS);
                setBalanceMeme(parseFloat(formattedBalanceAfter));
                toast.success('Transfer successful!');
            } else {
                console.error("Transfer failed!");
            }

        } catch (error) {
            console.error('Transaction failed:', error);
            toast.error('Transaction failed. Please try again!');
        } finally {
            setLoading(false);
        }
    };

    const [form, setForm] = useState({ sender: '' })
    const { pathname } = useLocation();
    const account = useAccount()
    useEffect(() => {
        getAccount()
        getTokenBalance(squad?.contract_address, form.sender)
    }, [pathname])
    console.log('account', account)
    useAccountEffect({
        onConnect(data) {
            console.log('Connected!', data)
            getTokenBalance(squad?.contract_address, data.address)
        },
        onDisconnect() {
            console.log('Disconnected!')
            setBalanceMeme(0)
        },
    })
    console.log(form)

    const getAccount = async () => {
        if (typeof window.ethereum === 'undefined') {
            return;
        }
        try {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            if (Array.isArray(accounts) && accounts.length > 0) {
                setForm({
                    ...form,
                    sender: accounts[0],
                });
            }
        } catch (error) {
            console.error('error:', error);
        }
    };
    function formatNumber(num: number) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function convertBalanceMeme(tokenBalance: any) {
        const balanceStr = tokenBalance.toString();
        const formattedBalance = balanceStr.replace(/0+$/, '');
        return formattedBalance;
    }

    async function getTokenBalance(contractAddress: string, userAddress: string) {
        try {
            const response = await axios.get('https://api-testnet.bscscan.com/api', {
                params: {
                    module: 'account',
                    action: 'tokenbalance',
                    contractaddress: contractAddress,
                    address: userAddress,
                    tag: 'latest',
                    apikey: `${ENVS.VITE_BSCSCAN_KEY}`
                }
            });

            if (response.data.status === '1') {
                setBalanceMeme(convertBalanceMeme(response.data.result))
            } else {
                console.error('Error fetching token balance:', response.data.message);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    return (
        <>
            {squad && (
                <Wrapper>
                    <div className='desktop-display'>
                        <div className='w-100 flex justify-center'>
                            <div className='block' style={{ maxWidth: "1280px" }}>

                                <div className='flex pt-10 pb-10'>
                                    <img className='cursor-pointer' src={LeftArrow} alt='' onClick={() => navigate('/memes')} />
                                    <div className='ml-8 secondary-font text-[80px] font-normal'>
                                        Payment
                                    </div>
                                </div>
                                <div className='flex justify-between'>
                                    {squad && (
                                        <>
                                            <div className='w-[50%]'>
                                                <div
                                                    className='bg-center'
                                                    style={{
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundSize: 'cover'
                                                    }}
                                                >
                                                    <div className="relative">
                                                        <img src={frame} alt="absolute" className="relative z-10" />
                                                        <img
                                                            src={squad.ai_avatar}
                                                            alt=""
                                                            className="absolute inset-0 w-full h-full object-cover"
                                                        />
                                                        {user && user?.squad_id == id &&
                                                            <div className='flex border-[#00FF00] border absolute bottom-10 right-8 rounded-2xl justify-center items-center p-2' style={{ zIndex: '10', width: '30%' }}>
                                                                <span className='fw-bold text-[#00FF00]'>Your Squad</span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-[50%] min-h-[400px] bg-[#222933] rounded-lg'>
                                                <div className='flex'>
                                                    <div
                                                        className='py-10 px-8 w-full'
                                                    >
                                                        <div className='w-100'>
                                                            <div className='flex justify-between items-center'>
                                                                <div className=' text-2xl text-white'>Miss {squad.country}</div>
                                                                {/* <MetaMaskButton theme={"dark"} color="orange"></MetaMaskButton> */}
                                                                {/* {walletAddress ? (
                                                                    <div style={{ position: "relative", display: "inline-block" }}>
                                                                        <div className="relative inline-block">
                                                                            <button
                                                                                className="bg-gray-700 flex space-x-2 items-center text-white px-5 py-2 rounded focus:outline-none"
                                                                                onClick={toggleDropdown}
                                                                            >
                                                                                <span>{shortenAddress(walletAddress)}</span>
                                                                                <i className="fas fa-chevron-down"></i>
                                                                            </button>
                                                                            {isOpen && (
                                                                                <ul className="absolute mt-2 bg-white border border-gray-300 rounded shadow-lg z-10 w-48 right-0">
                                                                                    <li>
                                                                                        <button
                                                                                            onClick={disconnectWallet}
                                                                                            className='px-4 py-2 cursor-pointer text-[#333] z-10'
                                                                                        >
                                                                                            Disconnect
                                                                                        </button>
                                                                                    </li>
                                                                                </ul>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <button
                                                                        onClick={handleConnectWallet}
                                                                        className='bg-orange-400 color-white flex items-center px-4 py-2 rounded-lg cursor-pointer'
                                                                    >
                                                                        <img src={metamask} alt="" className='w-8 h-8' />
                                                                        Connect Wallet
                                                                    </button>
                                                                )} */}
                                                                <ConnectButton showBalance={false} />
                                                            </div>
                                                        </div>
                                                        <div className='my-3 border-t border-[#605656]'></div>
                                                        <div className='gap-4 mt-5 mb-5'>
                                                            <div className='py-2'>Amount</div>
                                                            <div className='relative flex items-center bg-[#00000000]'>
                                                                <img src={xp} className='h-8 w-8 absolute z-10 left-5 top-1/2 transform -translate-y-1/2' alt="" />
                                                                <input
                                                                    type="number"
                                                                    value={amount}
                                                                    onChange={handleChange}
                                                                    step="0.001"
                                                                    style={{
                                                                        borderWidth: 1,
                                                                        borderColor: 'rgba(255, 255, 255, 0.2)',
                                                                    }}
                                                                    placeholder='1'
                                                                    className="primary-font pl-16 pr-6 font-bold bg-[#00000000] text-base w-full h-[60px] placeholder-custom text-white flex items-center justify-center rounded-[20px] focus:outline-none focus:border-transparent"
                                                                />
                                                            </div>
                                                            <div className='py-2 mt-3'>Cost</div>
                                                            <div className='relative flex items-center bg-[#00000000]'>
                                                                <img src={squad.ai_avatar} className='h-8 w-8 absolute z-10 left-5 top-1/2 transform -translate-y-1/2 rounded-2xl' alt="" />
                                                                <input
                                                                    type="number"
                                                                    value={amount}
                                                                    onChange={handleChange}
                                                                    step="0.001"
                                                                    style={{
                                                                        borderWidth: 1,
                                                                        borderColor: 'rgba(255, 255, 255, 0.2)',
                                                                    }}
                                                                    placeholder='1'
                                                                    className="primary-font pl-16 pr-6 font-bold bg-[#00000000] text-base w-full h-[60px] placeholder-custom text-white flex items-center justify-center rounded-[20px] focus:outline-none focus:border-transparent"
                                                                />
                                                            </div>
                                                            <div className='mt-3'>Your Balance: {formatNumber(balanceMeme)}</div>
                                                            <div className='flex justify-center mt-8'>
                                                                <div className={`bg-white text-center text-[#1B1D21] rounded-2xl w-48 py-3 cursor-pointer ${loading ? 'opacity-50 pointer-events-none' : ''}`}
                                                                    style={{
                                                                        paddingLeft: '3rem',
                                                                        paddingRight: '3rem',
                                                                    }}
                                                                    onClick={handleSendTransaction}
                                                                >
                                                                    {loading ? "Processing..." : "Submit"}
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className='my-24'></div>
                    </div>
                    <div className='mobile-display'>
                        <div className='flex mx-4 py-10'>
                            <img className='cursor-pointer' src={LeftArrow} alt='' onClick={() => navigate('/memes')} />
                            <div className='ml-8 primary-font text-xl font-semibold'>
                                Payment
                            </div>
                        </div>
                        <div className='block'>
                            {squad && (
                                <>
                                    <div className='w-100'>
                                        <div
                                            className='bg-center'
                                            style={{
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'cover'
                                            }}
                                        >
                                            <div className="relative">
                                                <img src={frame} alt="absolute" className="relative z-10" />
                                                <img
                                                    src={squad.ai_avatar}
                                                    alt=""
                                                    className="absolute inset-0 w-full h-full object-cover"
                                                />
                                                {
                                                    user && user?.squad_id == id &&
                                                    <div className='flex border-[#00FF00] border absolute bottom-8 right-8 rounded-2xl justify-center items-center p-2' style={{ zIndex: '10', width: '30%' }}>
                                                        <span className='fw-bolder text-[#00FF00]'>Your Squad</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='min-h-[400px] bg-[#222933] rounded-lg mb-5'>
                                        <div className='flex'>
                                            <div
                                                className='py-10 px-8 w-full'
                                            >
                                                <div className='my-2 flex justify-end'>
                                                    <ConnectButton showBalance={false} />
                                                </div>
                                                <div className='w-100'>
                                                    <div className='flex justify-between items-center'>
                                                        <div className=' text-2xl text-white'>Miss {squad.country}</div>
                                                        {/* <MetaMaskButton theme={"dark"} color="orange"></MetaMaskButton> */}
                                                    </div>
                                                </div>
                                                <div className='my-3 border-t border-[#605656]'></div>
                                                <div className='gap-4 mt-5 mb-5'>
                                                    <div className='py-2'>Amount</div>
                                                    <div className='relative flex items-center bg-[#00000000]'>
                                                        <img src={xp} className='h-8 w-8 absolute z-10 left-5 top-1/2 transform -translate-y-1/2' alt="" />
                                                        <input
                                                            type="number"
                                                            value={amount}
                                                            onChange={handleChange}
                                                            step="0.001"
                                                            style={{
                                                                borderWidth: 1,
                                                                borderColor: 'rgba(255, 255, 255, 0.2)',
                                                            }}
                                                            placeholder='1'
                                                            className="primary-font pl-16 pr-6 font-bold bg-[#00000000] text-base w-full h-[60px] placeholder-custom text-white flex items-center justify-center rounded-[20px] focus:outline-none focus:border-transparent"
                                                        />
                                                    </div>
                                                    <div className='py-2 mt-3'>Cost</div>
                                                    <div className='relative flex items-center bg-[#00000000]'>
                                                        <img src={squad.ai_avatar} className='h-8 w-8 absolute z-10 left-5 top-1/2 transform -translate-y-1/2 rounded-2xl' alt="" />
                                                        <input
                                                            type="number"
                                                            value={amount}
                                                            onChange={handleChange}
                                                            step="0.001"
                                                            style={{
                                                                borderWidth: 1,
                                                                borderColor: 'rgba(255, 255, 255, 0.2)',
                                                            }}
                                                            placeholder='1'
                                                            className="primary-font pl-16 pr-6 font-bold bg-[#00000000] text-base w-full h-[60px] placeholder-custom text-white flex items-center justify-center rounded-[20px] focus:outline-none focus:border-transparent"
                                                        />
                                                    </div>
                                                    <div className='mt-3'>Your Balance: {formatNumber(balanceMeme)}</div>
                                                    <div className='flex justify-center mt-8'>
                                                        <div className={`bg-white text-center text-[#1B1D21] rounded-2xl w-48 py-3 cursor-pointer ${loading ? 'opacity-50 pointer-events-none' : ''}`}
                                                            style={{
                                                                paddingLeft: '3rem',
                                                                paddingRight: '3rem',
                                                            }}
                                                            onClick={handleSendTransaction}
                                                        >
                                                            {loading ? "Processing..." : "Submit"}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <Footer />
                </Wrapper>
            )}
        </>
    );
};

export const PurcharMeme = memo(PurcharMemeComponent);
