import React, { memo, useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Wrapper } from './details.styled';
import LeftArrow from '@/assets/left-arrow.svg';
import instagram from '@/assets/icon/instagram.png';
import { Footer } from '@/components/footer/footer';
import { getAiSquad, getSquadById, getSquadLeaderboard } from '@/utilities/miss-data';
import { Link } from 'react-router-dom';
import ConnectPopUp from './connect/show';
import frame from '@/assets/icon/frame.png';
import button from '@/assets/button.png'
import { LoginButton } from '@telegram-auth/react';
import { MemeMob } from './meme-mob'
import { Chart } from './chart';
import DexScreenerEmbed from './dex-screen'
import { Loading } from '@/components/loading/loading';

type TelegramAuthData = {
    id: number;
    username?: string;
    first_name?: string;
    last_name?: string;
    auth_date?: number;
    hash: string;
};
type AuthData = {
    user_id: number;
    username?: string;
    address?: string;
    country?: string;
    miss: string;
    avatar: string;
    flag: string;
    ai_avatar: string;
    squad_id: string;
};
const MissAiDetailComponent = () => {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const [squad, setSquad] = useState<any>({});

    useEffect(() => {
        window.scrollTo(0, 0);
        if (id) {
            async function fetchSquadDetailData() {
                const squadResponse = await getSquadById(id);
                if (squadResponse) {
                    setSquad(squadResponse.data);
                }
            }
            fetchSquadDetailData();
        }
    }, []);

    const [data, setData] = useState<TelegramAuthData | null>(null);
    const [user, setUser] = useState<AuthData | null>(null);
    useEffect(() => {
        try {
            const storedData = localStorage.getItem('authData');
            if (storedData) {
                const parsedData = JSON.parse(storedData);

                if (parsedData && parsedData.id && parsedData.username) {
                    setData(parsedData);
                    getAiSquad(parsedData.id).then((squadResponse) => {
                        if (squadResponse && squadResponse.data && squadResponse.data.length > 0) {
                            setUser(squadResponse.data[0]);
                        }
                    }).catch((error) => {
                        console.error('Error fetching squad data:', error);
                    });
                } else {
                    console.error('Clearing data.');
                    localStorage.removeItem('authData');
                }
            }
        } catch (error) {
            localStorage.removeItem('authData');
        }
    }, []);
    const handleAuthCallback = async (authData: TelegramAuthData) => {
        try {
            setData(authData);
            localStorage.setItem('authData', JSON.stringify(authData));

            const squadResponse = await getAiSquad(authData.id);
            if (squadResponse && squadResponse.data && squadResponse.data.length > 0) {
                setUser(squadResponse.data[0]);
                console.log(squadResponse.data[0]);
            }
        } catch (error) {
            console.error('Error in handleAuthCallback:', error);
        }
    };
    const [isOpen, setIsOpen] = useState(false);
    const handleOpenPayment = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };
    const handleLogout = () => {
        localStorage.removeItem('authData');
        setData(null);
    };
    function formatNumber(num: number): string {
        let formattedNum = num.toString();

        if (formattedNum.includes('.')) {
            formattedNum = formattedNum.replace(/(\.\d*?[1-9])0+$/g, '$1');
        }

        formattedNum = formattedNum.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        return formattedNum;
    }
    const [isLoading, setIsLoading] = useState(true);

    useLayoutEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 3000);
    }, []);
    return (
        <>
            {squad && (
                <Wrapper>
                    {isLoading && <Loading />}
                    <div className='desktop-display'>
                        <div className='w-100 flex justify-center'>
                            <div className='block' style={{ maxWidth: "1280px" }}>

                                <div className='flex pt-10 pb-10'>
                                    <img className='cursor-pointer' src={LeftArrow} alt='' onClick={() => navigate('/memes')} />
                                    <div className='ml-8 secondary-font text-[80px] font-normal'>
                                        Detail
                                    </div>
                                </div>
                                <div className='flex justify-between'>
                                    {squad && (
                                        <>
                                            <div className='w-[50%]'>
                                                <div
                                                    className='bg-center'
                                                    style={{
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundSize: 'cover'
                                                    }}
                                                >
                                                    <div className="relative">
                                                        <img src={frame} alt="absolute" className="relative z-10" />
                                                        <img
                                                            src={squad.ai_avatar}
                                                            alt=""
                                                            className="absolute inset-0 w-full h-full object-cover"
                                                        />
                                                        {user && user?.squad_id == id &&
                                                            <div className='flex border-[#00FF00] border absolute bottom-10 right-8 rounded-2xl justify-center items-center p-2' style={{ zIndex: '10', width: '30%' }}>
                                                                <span className='fw-bold text-[#00FF00]'>Your Squad</span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='w-[50%] min-h-[400px] bg-[#222933] rounded-lg'>
                                                <div className='flex'>
                                                    <div
                                                        className='py-10 px-8 w-full'
                                                    >
                                                        <div className='w-100'>
                                                            <div className='text-2xl text-[#00FF00]'>${squad.symbol_meme}</div>
                                                            <div className='text-xl text-white mt-2'>Miss {squad.country}</div>
                                                            <div className='flex justify-between items-center'>
                                                                <span>Instagram</span>
                                                                <Link to={squad.instagram} target='blank'>
                                                                    <img src={instagram} className='w-10 h-10' alt='' />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div className='my-3 border-t border-[#605656]'></div>
                                                        <div>
                                                            {/* <div className=' text-2xl text-white text-center'>{squad.miss}</div> */}
                                                            {/* <div className='text-center text-[#FFFFFF80] mt-4 max-h-[150px] overflow-y-auto px-2'>{squad.information}</div> */}
                                                        </div>
                                                        <div className='rounded-xl p-4 w-100 border border-[#FFFFFF33] mt-10'>
                                                            <div className='flex space-x-1 items-end'>
                                                                <span>
                                                                    Market Cap:
                                                                </span>
                                                                <span>
                                                                    ${formatNumber(parseInt(squad?.market_cap))}
                                                                </span>
                                                                {
                                                                    squad.gta > 0 ? (
                                                                        <span className='text-sm text-green-500'>
                                                                            ({`${formatNumber(squad.gta)}`}%)
                                                                        </span>
                                                                    ) : squad.gta < 0 ? (
                                                                        <span className='text-sm text-red-500'>
                                                                            ({`${formatNumber(squad.gta)}`}%)
                                                                        </span>
                                                                    ) : (
                                                                        <span className='text-sm text-green-500'>
                                                                            (0%)
                                                                        </span>
                                                                    )
                                                                }
                                                            </div>
                                                            <div className='flex space-x-2 mt-5'>
                                                                <Link target='blank' className='rounded-[40px] bg-white text-[#1B1D21] flex-[1] flex items-center justify-center py-3' to={squad?.pancake_link}>
                                                                    Buy Meme Coin
                                                                </Link>
                                                                {data &&
                                                                    <>
                                                                        {
                                                                            user && user?.squad_id == id &&
                                                                            <div onClick={handleOpenPayment} className='rounded-2xl text-white w-100 flex-[1] text-center py-3 border border-[#FFFFFF]'
                                                                                style={{ boxShadow: '0px 0px 2.49px 0px #000000 ' }}>
                                                                                Transfer To XP
                                                                            </div>
                                                                        }
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                        {/* {!data &&
                                                            <div className='flex pb-1 justify-center w-100 mt-4'>
                                                                <LoginButton
                                                                    botUsername='misscharm_ai_bot'
                                                                    authCallbackUrl=""
                                                                    buttonSize="large"
                                                                    cornerRadius={5}
                                                                    showAvatar={true}
                                                                    lang="en"
                                                                    onAuthCallback={handleAuthCallback}
                                                                />
                                                            </div>
                                                        } */}
                                                        {data ?
                                                            <div className='text-center p-3 mt-3'>
                                                                <div className='block pb-2'>Welcome, {data?.username || data?.first_name && data?.last_name}!</div>
                                                                <div className='flex justify-center'>
                                                                    <button
                                                                        onClick={handleLogout}
                                                                        className="bg-[#54a9eb] text-white px-2 py-1 rounded-md flex space-x-2 items-center"
                                                                    >
                                                                        <i className="fab fa-telegram text-2xl"></i>
                                                                        <div>
                                                                            Logout Telegram
                                                                        </div>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className='flex p-3 justify-center'>
                                                                <>
                                                                    <MemeMob />
                                                                </>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className='pt-5'>
                                    <DexScreenerEmbed pairId={squad?.pair_id} />
                                </div>
                            </div>
                        </div>
                        <div className='my-24'></div>
                    </div>
                    {/* <MemeMob /> */}
                    <div className='mobile-display mb-5'>
                        <div className='flex mx-4 py-10'>
                            <img className='cursor-pointer' src={LeftArrow} alt='' onClick={() => navigate('/memes')} />
                            <div className='ml-8 primary-font text-xl font-semibold'>
                                Detail
                            </div>
                        </div>
                        <div className='block'>
                            {squad && (
                                <>
                                    <div className='w-100'>
                                        <div
                                            className='bg-center'
                                            style={{
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'cover'
                                            }}
                                        >
                                            <div className="relative">
                                                <img src={frame} alt="absolute" className="relative z-10" />
                                                <img
                                                    src={squad.ai_avatar}
                                                    alt=""
                                                    className="absolute inset-0 w-full h-full object-cover"
                                                />
                                                {
                                                    user && user?.squad_id == id &&
                                                    <div className='flex border-[#00FF00] border absolute bottom-8 right-8 rounded-2xl justify-center items-center p-2' style={{ zIndex: '10', width: '30%' }}>
                                                        <span className='fw-bolder text-[#00FF00]'>Your Squad</span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='w-[100%] min-h-[400px] bg-[#222933] rounded-lg'>
                                        <div className='flex'>
                                            <div
                                                className='py-10 px-8 w-full'
                                            >
                                                <div className='w-100'>
                                                    <div className=' text-2xl text-[#00FF00]'>${squad.symbol_meme}</div>
                                                    <div className=' text-xl text-white mt-2'>Miss {squad.country}</div>
                                                    <div className='flex justify-between items-center'>
                                                        <span>Instagram</span>
                                                        <Link to={squad.instagram} target='blank'>
                                                            <img src={instagram} className='w-10 h-10' alt='' />
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className='my-3 border-t border-[#605656]'></div>
                                                <div>
                                                    {/* <div className=' text-2xl text-white text-center'>{squad.miss}</div> */}
                                                    {/* <div className='text-center text-[#FFFFFF80] mt-4'>{squad.information}</div> */}
                                                </div>
                                                <div className='rounded-xl mb-8 p-4 w-100 border border-[#FFFFFF33] mt-5'>
                                                    <div className='flex space-x-1 items-end'>
                                                        <span>
                                                            Market Cap:
                                                        </span>
                                                        <span>
                                                            ${formatNumber(parseInt(squad?.market_cap))}
                                                        </span>
                                                        {
                                                            squad.gta > 0 ? (
                                                                <span className='text-sm text-green-500'>
                                                                    ({`${formatNumber(squad.gta)}`}%)
                                                                </span>
                                                            ) : squad.gta < 0 ? (
                                                                <span className='text-sm text-red-500'>
                                                                    ({`${formatNumber(squad.gta)}`}%)
                                                                </span>
                                                            ) : (
                                                                <span className='text-sm text-green-500'>
                                                                    (0%)
                                                                </span>
                                                            )
                                                        }
                                                    </div>
                                                    <div className='flex space-x-2 mt-3'>
                                                        <Link className='rounded-2xl bg-white text-[#1B1D21] w-100 flex-[1] text-center py-3' to={squad?.pancake_link}>
                                                            Buy Meme Coin
                                                        </Link>
                                                        {data &&
                                                            <>
                                                                {
                                                                    user && user?.squad_id == id &&
                                                                    <div onClick={handleOpenPayment} className='rounded-2xl text-white w-100 flex-[1] text-center py-3 border border-[#FFFFFF]'
                                                                        style={{ boxShadow: '0px 0px 2.49px 0px #000000 ' }}>
                                                                        Transfer To XP
                                                                    </div>

                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                                {data ?
                                                    <div className='text-center p-1'>
                                                        <div className='block pb-2'>Welcome, {data?.username || data?.first_name && data?.last_name}!</div>
                                                        <div className='flex justify-center'>
                                                            <button
                                                                onClick={handleLogout}
                                                                className="bg-[#54a9eb] text-white px-2 py-1 rounded-md flex space-x-2 items-center"
                                                            >
                                                                <i className="fab fa-telegram text-2xl"></i>
                                                                <div>
                                                                    Logout Telegram
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    :
                                                    <>
                                                        <MemeMob />
                                                        <div className='flex justify-center'>
                                                            <LoginButton
                                                                botUsername='misscharm_ai_bot'
                                                                authCallbackUrl=""
                                                                buttonSize="large"
                                                                cornerRadius={5}
                                                                showAvatar={true}
                                                                lang="en"
                                                                onAuthCallback={handleAuthCallback}
                                                            />
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {/* <Chart tokenId={100028330} squad={squad} /> */}
                                    <div className='pt-3'>
                                        <DexScreenerEmbed pairId={squad?.pair_id} />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <Footer />
                </Wrapper>
            )}
            <ConnectPopUp
                isOpen={isOpen}
                onRequestClose={handleClose}
                title="Connect Telegram Account"
                currentSquadId={id}
            />
        </>
    );
};

export const MissAiDetail = memo(MissAiDetailComponent);
