import React from 'react';

import { ThemeProvider, ThemedGlobalStyle } from '@/theme';
import { RouterProvider } from '@/providers/router.provider';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const App = () => {
  return (
    <ThemeProvider>
      <ThemedGlobalStyle />
      <RouterProvider />
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        pauseOnHover
      />
    </ThemeProvider>
  );
};
