import React from 'react';
import { Wrapper } from './dex-screen.styled';
interface DexProps {
    pairId: any;
}
const DexScreenerEmbed: React.FC<DexProps> = (pairId) => {
    const url = `https://dexscreener.com/bsc/${pairId.pairId}?embed=1&loadChartSettings=0&chartLeftToolbar=0&chartTheme=dark&theme=dark&chartStyle=0&chartType=usd&interval=15`
    console.log(pairId.pairId)
    return (
        <Wrapper>
            <div id="dexscreener-embed">
                <iframe
                    src={url}
                    style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                        border: 0
                    }}
                    frameBorder="0"
                    allowFullScreen
                ></iframe>
            </div>
        </Wrapper>
    );
};

export default DexScreenerEmbed;
