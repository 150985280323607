import React, { memo, useEffect, useState } from 'react';
import { getAiSquad, getSquadById, getSquadLeaderboard } from '@/utilities/miss-data';
import { LoginButton } from '@telegram-auth/react';
type TelegramAuthData = {
    id: number;
    username?: string;
    first_name?: string;
    last_name?: string;
    auth_date?: number;
    hash: string;
};

const MemeMobComponent = () => {
    const handleAuthCallback = async (authData: TelegramAuthData) => {
        try {
            localStorage.setItem('authData', JSON.stringify(authData));

            const squadResponse = await getAiSquad(authData.id);
            if (squadResponse && squadResponse.data && squadResponse.data.length > 0) {
                console.log(squadResponse.data[0]);
            }
        } catch (error) {
            console.error('Error in handleAuthCallback:', error);
        }
    };
    
    return (
        <>
            <LoginButton
                botUsername='misscharm_ai_bot'
                authCallbackUrl=""
                buttonSize="large"
                cornerRadius={5}
                showAvatar={true}
                lang="en"
                onAuthCallback={handleAuthCallback}
            />
        </>
    );
};

export const MemeMob = memo(MemeMobComponent);
