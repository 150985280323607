import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { LoginButton } from '@telegram-auth/react';
import { getAiSquad } from '@/utilities/miss-data';
import frame from '@/assets/icon/frame.png';
import { Link, useNavigate } from 'react-router-dom';
interface PopUpProps {
    isOpen: boolean;
    onRequestClose: () => void;
    title: string;
    currentSquadId: any;
}
type TelegramAuthData = {
    id: number;
    username?: string;
    first_name?: string;
    last_name?: string;
    auth_date?: number;
    hash: string;
};
type AuthData = {
    user_id: number;
    username?: string;
    address?: string;
    country?: string;
    miss: string;
    avatar: string;
    flag: string;
    ai_avatar: string;
    squad_id: number;
};
const ConnectPopUp: React.FC<PopUpProps> = ({ isOpen, onRequestClose, title, currentSquadId }) => {
    const [data, setData] = useState<TelegramAuthData | null>(null);
    const [user, setUser] = useState<AuthData | null>(null);
    const navigate = useNavigate();
    const handleAuthCallback = async (authData: TelegramAuthData) => {
        try {
            setData(authData);
            localStorage.setItem('authData', JSON.stringify(authData));

            const squadResponse = await getAiSquad(authData.id);
            if (squadResponse && squadResponse.data && squadResponse.data.length > 0) {
                setUser(squadResponse.data[0]);
                console.log(squadResponse.data[0]);
            }
        } catch (error) {
            console.error('Error in handleAuthCallback:', error);
        }
    };

    useEffect(() => {
        try {
            const storedData = localStorage.getItem('authData');
            if (storedData) {
                const parsedData = JSON.parse(storedData);

                if (parsedData && parsedData.id && parsedData.username) {
                    setData(parsedData);

                    getAiSquad(parsedData.id).then((squadResponse) => {
                        if (squadResponse && squadResponse.data && squadResponse.data.length > 0) {
                            setUser(squadResponse.data[0]);
                        }
                    }).catch((error) => {
                        console.error('Error fetching squad data:', error);
                    });
                } else {
                    console.error('Clearing data.');
                    localStorage.removeItem('authData');
                }
            }
        } catch (error) {
            localStorage.removeItem('authData');
        }
    }, []);
    const [isModal, setIsModal] = useState(false);
    const handleDetailsClick = () => {
        setIsModal(true);
        // onRequestClose()
    };

    const handleClose = () => {
        setIsModal(false);
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Pop Up Modal"
            className="max-w-4xl z-40 mx-auto bg-[#222933] rounded-lg shadow-lg p-6 w-full"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-40"
        >
            <div className='flex items-center'>
                <span className='flex-1'></span>
                <span className='flex-1'></span>
                <span className='flex-1 flex justify-end'>
                    <button
                        className=""
                        onClick={onRequestClose}
                    >
                        <i className="fa fa-times text-xl" aria-hidden="true"></i>
                    </button>

                </span>
            </div>
            <div>
                <h2 className="flex-2 text-2xl font-bold text-center">{title}</h2>
            </div>
            <div className='gap-4 mt-5 mb-5'>
                {!data &&
                    <div className='flex pb-1 justify-center'>
                        <LoginButton
                            botUsername='misscharm_ai_bot'
                            authCallbackUrl=""
                            buttonSize="large"
                            cornerRadius={5}
                            showAvatar={true}
                            lang="en"
                            onAuthCallback={handleAuthCallback}
                        />
                    </div>
                }
                {data && user ?
                    <>
                        <div className="flex space-x-2">
                            <div className="flex-[1] mb-4 md:mb-0">
                                <div className="relative">
                                    <img src={frame} alt="absolute" className="relative z-10" />
                                    <img
                                        src={user?.ai_avatar}
                                        alt=""
                                        className="absolute inset-0 w-full h-full object-cover"
                                    />
                                </div>
                            </div>
                            <div className="flex-[1] p-3">
                                <span className="block">Hi: {data?.username || data?.first_name && data?.last_name}</span>
                                <span className="block">Your Squad: {user?.country}</span>
                                <span className="block">Miss: {user?.miss}</span>
                            </div>
                        </div>
                        <div className='flex justify-center'>
                            {
                                user?.squad_id == currentSquadId ?
                                    <div className='mt-3 bg-white text-center text-[#1B1D21] rounded-2xl w-100 py-2'
                                        style={{
                                            paddingLeft: '3rem',
                                            paddingRight: '3rem',
                                        }}
                                        onClick={() => navigate(`/memes/purchar/${currentSquadId}`)}
                                    >
                                        Continue
                                    </div>
                                    :
                                    <a className='mt-3 bg-white text-center text-[#1B1D21] rounded-2xl w-100 p-2'
                                        style={{
                                            paddingLeft: '3rem',
                                            paddingRight: '3rem',
                                        }}
                                        href={'/memes/detail/' + user?.squad_id}>
                                        Go Your Squad
                                    </a>
                            }

                        </div>
                    </>
                    : <></>
                }
                {/* <div
                    className='mt-3 bg-white text-center text-[#1B1D21] rounded-2xl w-50 py-2'
                    style={{
                        paddingLeft: '3rem',
                        paddingRight: '3rem',
                    }}
                    onClick={() => navigate(`/meme/purchar/${currentSquadId}`)}
                >
                    Continue
                </div> */}
            </div>
        </Modal>
    );
};

export default ConnectPopUp;
