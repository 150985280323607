import { MissDetail } from '@/modules/miss-view/miss-detail';
import { AllMissPage } from '@/modules/miss-view/misspage';
import { MissAiDetail } from '@/modules/ai/components/details';
import { PurcharMeme } from '@/modules/ai/components/payment/purchar';
import { ListMeme } from '@/modules/ai/list-meme'
import { Import } from '@/utilities/import.utils';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const Homepage = Import({
  touch: React.lazy(() => import('../modules/homepage')),
  desktop: React.lazy(() => import('../modules/homepage'))
}) as React.LazyExoticComponent<React.MemoExoticComponent<() => JSX.Element>>;

const Aipage = Import({
  touch: React.lazy(() => import('../modules/ai')),
  desktop: React.lazy(() => import('../modules/ai'))
}) as React.LazyExoticComponent<React.MemoExoticComponent<() => JSX.Element>>;
const elements = [
  {
    Component: Homepage,
    path: '/'
  },
  {
    Component: Aipage,
    path: '/memes'
  }
];

export const RouterProvider = () => {
  return (
    <BrowserRouter>
      {/* <Header /> */}
      <Routes>
        {elements.map(({ Component, path }, idx) => (
          <Route
            key={idx}
            element={
              <React.Suspense fallback={null}>
                <Component />
              </React.Suspense>
            }
            path={path}
          />
        ))}
        <Route
          element={
            <React.Suspense fallback={null}>
              <AllMissPage />
            </React.Suspense>
          }
          path='/miss-list'
        />
        <Route
          element={
            <React.Suspense fallback={null}>
              <ListMeme />
            </React.Suspense>
          }
          path='/list-meme'
        />
        <Route
          element={
            <React.Suspense fallback={null}>
              <MissDetail />
            </React.Suspense>
          }
          path='/miss-detail/:id'
        />
        <Route
          element={
            <React.Suspense fallback={null}>
              <MissAiDetail />
            </React.Suspense>
          }
          path='/memes/detail/:id'
        />
        <Route
          element={
            <React.Suspense fallback={null}>
              <PurcharMeme />
            </React.Suspense>
          }
          path='/memes/purchar/:id'
        />
      </Routes>
    </BrowserRouter>
  );
};
